.total {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
}

.Calculator {
    display: flex;
    width: max-content;
    flex-direction: column;
}

.Map {
    position: relative;
    height: 100%;
    width: 100%;
}

.routeMap {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 99%;
    max-height: 99%;
}

.pathlist {
    background-color: #b3b3b3;
    overflow-y: scroll;
    padding: 10px;
    text-align: left;
    height: max-content;
}

.form {
    background: #dbdbdb;
    padding: 5px 45px;
    text-align: center;
    min-width: fit-content;
    width: 400px;
    height: fit-content;
}

.submit {
    font-family: sans-serif;
    text-transform: uppercase;
    outline: 0;
    width: 100%;
    background: #00cbfd;
    border: 0;
    border-radius: 5px;
    padding: 15px;
    color: #ffffff;
    font-size: 14px;
    transition: all 0.3 ease;
    cursor: pointer;
}

.message {
    color: #ce0000;
    font-size: 12px;
}
